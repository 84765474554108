import React from 'react';
import { useLocation } from 'react-router-dom';
import { RegisterLoginForm } from 'cccisd-laravel-nexus';
import axios from 'cccisd-axios';
import style from './style.css';
import { Field, CccisdPasswordInput, CccisdCheckboxInput } from 'cccisd-formik';
import validator from 'cccisd-validator';
import Notification from 'cccisd-notification';

const Boilerplate = window.cccisd.boilerplate;
// const Fortress = window.cccisd.fortress;

const SignUp = props => {
    const redirectUrl = new URLSearchParams(useLocation().search).get('redirectUrl');

    const handleSubmit = async values => {
        // REGISTER FORM SUBMIT
        const response = await axios.post(Boilerplate.route('api.nexus.pawn.store'), {
            first_name: values.first_name.trim(),
            last_name: values.last_name.trim(),
            username: values.email.trim(),
            role: 'participant',
            email: values.email.trim(),
            password: values.password.trim(),
            repeatPassword: values.repeatPassword.trim(),
            selfRoster: true,
        });

        if (response?.data?.status === 'error') {
            if (response.data.errors.username.length > 0) {
                Notification({
                    message: response.data.errors.username[0],
                    type: 'danger',
                    duration: 5000,
                });
            } else {
                Notification({
                    message: 'An error occured during registration.',
                    type: 'danger',
                    duration: 5000,
                });
            }
        }

        // LOGIN
        if (response?.data?.status === 'success') {
            const loginResponse = await axios.post(Boilerplate.route('api.nexus.login'), {
                username: values.email.trim(),
                password: values.password,
            });
            if (loginResponse?.data?.status === 'success') {
                if (redirectUrl) {
                    window.location = redirectUrl;
                } else {
                    window.location = Boilerplate.url('/courses');
                }
            }
        }
    };

    const validateAdditionalFields = values => {
        let errors = {};

        if (!values.email) {
            errors.email = 'Email is required';
        }
        const isValid = validator(values.email.trim(), {
            types: ['email'],
        });

        if (isValid.status === 'fail') {
            errors.email = isValid.errors.email;
        }

        if (!values.first_name || values.first_name === '') {
            errors.first_name = 'First name is required';
        }
        if (!values.last_name || values.last_name === '') {
            errors.last_name = 'Last name is required';
        }
        if (values.password !== values.repeatPassword) {
            errors.password = 'Passwords must match.';
            errors.repeatPassword = 'Passwords must match.';
        }
        if (!values.termsandconds) {
            errors.termsandconds = 'Must accept terms and conditions to continue.';
        }
        return errors;
    };

    const additonalFields = () => {
        return (
            <div>
                <Field
                    name="password"
                    autoComplete="new-password" // stop the browser from autocompleting both the Register form and Login form
                    component={CccisdPasswordInput}
                    showPasswordToggle
                    label="Password:"
                />
                <Field
                    name="repeatPassword"
                    autoComplete="new-password" // stop the browser from autocompleting both the Register form and Login form
                    component={CccisdPasswordInput}
                    showPasswordToggle
                    label="Confirm Password:"
                />
                <Field
                    name="termsandconds"
                    label={
                        <span>
                            I accept the{' '}
                            <a href="https://www.3cisd.com/privacy-policy/" rel="noopener noreferrer" target="_blank">
                                privacy policy
                            </a>{' '}
                            and{' '}
                            <a href="https://www.3cisd.com/terms-of-use/" rel="noopener noreferrer" target="_blank">
                                terms of use
                            </a>
                            .
                        </span>
                    }
                    component={CccisdCheckboxInput}
                    labelNotBold
                />
            </div>
        );
    };

    return (
        <div>
            <div className={style.formBox}>
                <RegisterLoginForm
                    registerProps={{
                        renderAdditionalFields: additonalFields,
                        onRegisterSubmit: handleSubmit,
                        validateAdditionalFields,
                        title: 'Register As Course Participant',
                    }}
                    loginProps={{
                        onSuccess: () => {
                            if (redirectUrl) {
                                window.location = redirectUrl;
                            } else {
                                window.location = Boilerplate.url('/courses');
                            }
                        },
                        title: 'Log In',
                        buttonText: 'Log In',
                    }}
                />
            </div>
        </div>
    );
};
export default SignUp;
