import React from 'react';
import { LoginForm } from 'cccisd-laravel-nexus';
const Login = () => {
    return (
        <div style={{ width: '450px', margin: 'auto' }}>
            <LoginForm />
        </div>
    );
};

export default Login;
